import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import ProjectsLayout from '../components/PagesLayout/projects';

const Projects = ({ data }) => {
  return <ProjectsLayout data={data} />;
};

export default Projects;

Projects.propTypes = {
  data: PropTypes.shape({
    projectsContent: PropTypes.shape({
      data: PropTypes.shape({
        flags_selector: PropTypes.array.isRequired,
        map_label_country: PropTypes.object.isRequired,
        map_label_filter: PropTypes.object.isRequired,
        map_label_service: PropTypes.object.isRequired,
        project_page_body: PropTypes.object.isRequired,
        project_page_graphics: PropTypes.object.isRequired,
        project_page_title: PropTypes.object.isRequired,
        selection_tile_label_country: PropTypes.object.isRequired,
        selection_tile_label_per_country: PropTypes.object.isRequired,
        selection_tile_label_service: PropTypes.object.isRequired,
        selection_tile_per_service: PropTypes.object.isRequired,
        similar_projects_title: PropTypes.object.isRequired,
      }).isRequired,
    }).isRequired,
    allProjects: PropTypes.object.isRequired,
  }).isRequired,
};

export const pageQuery = graphql`
         query projectsQuery($locale: String!) {
           prismicArticlesSection(lang: { eq: $locale }) {
             data {
               load_more_button_label {
                 text
               }
               number_of_articles_visible
               number_of_articles_to_load
             }
           }
           projectsContent: prismicProjectTile(lang: { eq: $locale }) {
             data {
               flags_selector {
                 country
                 longitude
                 latitude
                 flag_image {
                   url
                 }
               }
               map_label_country {
                 text
               }
               map_label_filter {
                 text
               }
               map_label_service {
                 text
               }
               project_page_body {
                 text
               }
               project_page_graphics {
                 document {
                   data {
                     title {
                       text
                     }
                     graphic {
                       body {
                         text
                       }
                       number
                       value
                     }
                   }
                 }
               }
               project_page_title {
                 text
               }
               selection_tile_label_country {
                 text
               }
               selection_tile_label_per_country {
                 text
               }
               selection_tile_label_service {
                 text
               }
               selection_tile_per_service {
                 text
               }
               all_label {
                 text
               }
               similar_projects_title {
                 text
               }
             }
           }
           allProjects: allPrismicProjectSection(
             filter: { lang: { eq: $locale } }
           ) {
             edges {
               node {
                 lang
                 uid
                 data {
                   countries {
                     country
                   }
                   download_project_pdf {
                     url
                   }
                   highlight_on_homepage
                   industry_selector_eng
                   industry_selector_fr
                   excerpt {
                     text
                   }
                   photo_horizontal {
                     desktop: localFile {
                       childImageSharp {
                         fluid(toFormat: JPG) {
                           ...GatsbyImageSharpFluid_noBase64
                         }
                       }
                     }
                   }
                   photo_horizontal {
                     mobile: localFile {
                       childImageSharp {
                         fluid(toFormat: JPG) {
                           ...GatsbyImageSharpFluid_noBase64
                         }
                       }
                     }
                   }
                   project_sections {
                     include_section_on_homepage_project_tile
                     project_section_body {
                       text
                     }
                     project_section_title {
                       text
                     }
                   }
                   tags {
                     text
                   }
                   title {
                     text
                   }
                 }
               }
             }
           }
           allPrismicExpertise(filter: { lang: { eq: $locale } }) {
             edges {
               node {
                 data {
                   service_block {
                     service_title {
                       text
                     }
                   }
                 }
               }
             }
           }
         }
       `;
